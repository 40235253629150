<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-6 col-md-6 col-lg-6 text-truncate-mess font-title my-auto"
        >
          文章定義一覧
        </h2>
        <div
          class="col-sm-6 col-md-6 col-lg-6 tex-left text-md-right text-sm-right"
        >
          <button class="button-create-user" @click="CreateSentence()">
            新規追加
          </button>
          <button v-on:click="gotoDashboard()" class="button-back-dashboard">
            戻る
          </button>
        </div>
      </div>
      <Tables
        :items="listSentence.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:id="{ index }">
          <td>{{ listSentence.current_page * limit - limit + index + 1 }}</td>
        </template>
        <template v-slot:status_order="{ item }">
          <td v-if="item.status_order == 1">未承認</td>
          <td v-else-if="item.status_order == 2">承認済み</td>
          <td v-else-if="item.status_order == 3">キャンセル</td>
        </template>
        <template v-slot:created_at="{ item }">
          <td>
            {{
              new Date(item.created_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:logout_at="{ item }">
          <td>
            {{
              new Date(item.logout_at).toLocaleString("ja-JP", {
                timeZone: "Asia/Tokyo",
              })
            }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center flex-nowrap">
            <div class="d-flex">
              <CButton
                class="mx-1 btn-other mb-0"
                color="primary bg-0083cf"
                square
                variant="outline"
                size="sm"
                v-on:click="copySentence(item.id)"
              >
                タグをコピー
              </CButton>
              <CButton
                class="mx-1 text-nowrap btn-other"
                square
                variant="outline"
                size="sm"
                v-on:click="editSentence(item.id)"
              >
                編集
              </CButton>
              <CButton
                class="mx-1 text-nowrap btn-del"
                square
                v-on:click="showConfirm(item.id)"
                variant="outline"
                size="sm"
                v-b-modal.delete-modal-sentence
              >
                削除
              </CButton>
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSentence.total"
      >
        <p class="font-weight-bold">
          {{ listSentence.total }}件中{{ listSentence.data.length }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSentence.last_page"
      >
        <CPagination
          v-if="listSentence.total"
          :activePage.sync="page"
          :pages="listSentence.last_page"
          align="center"
        />
      </div>
    </div>
    <div>
      <b-modal id="delete-modal-sentence" hide-header hide-footer>
        <div class="d-block text-center">
          <h5>削除しますと復元できませんのでご注意ください</h5>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            class="mx-2 mt-3 btn btn-danger"
            block
            v-on:click.prevent="removeSentence(dataModal)"
            >削除</b-button
          >
          <b-button
            class="mx-2 mt-3 btn btn-secondary"
            block
            @click="$bvModal.hide('delete-modal-sentence')"
          >
            キャンセル
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { Constants } from "../../utils/constants";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ListSentence",
  data() {
    return {
      fields: tableFields.SENTENCE,
      email_title: "",
      dataSentence: {
        id: "",
        shop_id: "",
      },
      limit: 50,
      page: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
  },
  async created() {
    const request = {
      shop_id: this.shop_id,
    };
    await this.getListSentence(request);
  },
  computed: {
    ...mapGetters(["listSentence", "message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSentence() {
      this.json_data = this.listSentence.data;
      this.page = this.listSentence.current_page;
    },
  },
  methods: {
    ...mapActions({ getListSentence: "getListSentence" }),
    ...mapActions({ deleteTimingMail: "deleteTimingMail" }),
    showConfirm(id) {
      this.dataModal = id;
    },
    async getSentences() {
      const request = {
        shop_id: this.shop_id,
      };
      this.getListSentence(request);
    },
    gotoDashboard() {
      if (this.$route.params.shopId) {
        this.$router.push({ name: "Dashboard Admin" });
      } else {
        this.$router.push({ name: "Dashboard Admin domain" });
      }
    },
    async removeSentence(id) {
      this.dataSentence.id = id;
      this.dataSentence.shop_id = this.shop_id;
      const dataReturn = await this.$store.dispatch(
        "deleteSentence",
        this.dataSentence
      );
      if (dataReturn.success) {
        this.$nextTick(() => {
          this.$bvModal.hide("delete-modal-sentence");
          this.getSentences();
        });
      } else {
        this.$toasted.error(dataReturn.message);
      }
    },
    editSentence(id) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Edit sentence"
          : "Edit sentence domain",
        params: { id: id },
      });
    },
    CreateSentence() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Create sentence"
          : "Create sentence domain",
      });
    },
    async copySentence(id) {
      const req = {
        id: id,
        shop_id: this.shop_id,
      };
      let resp = await this.$store.dispatch("showSentence", req);
      let copyText = resp.data.param;
      navigator.clipboard.writeText("{%" + copyText + "%}");
      this.$store.commit("set", ["message", Constants.MESSAGE_COPY]);
      this.$store.commit("set", ["success", true]);
    },
  },
};
</script>
